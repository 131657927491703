import React from 'react';
import MoorsledeImg from '../../../assets/images/references/blog/moorslede.jpg';
import {Helmet} from 'react-helmet';

const MoorsledeBlog = () => {
  return (

    <main className="mb-20 mt-10 w-full space-y-10 p-6 max-w-7xl ml-auto mr-auto">
      <Helmet>
        <meta name="description" content="Case-study Moorslede"/>
        <meta name="language" content="eng"/>
        <meta property="og:title" content="Real-time fill-level data helps Moorslede stay compliant with waste management legislation" />
        <meta property="og:description" content="Discover how smart sensors can optimize public waste collection" />
        <meta property='og:image' content="https://www.rebin.be/Iepersestraat.jpg" />
        <meta property="og:url" content="https://www.rebin.be/references/moorslede"></meta>
        <meta property="og:type" content="article"></meta>
        <meta property="og:site_name" content="rebin.be"/>
        <meta property="article:published_time" content="2024-07-29T13:38:24+02:00"></meta>
        <meta property="article:author" content="Rebin"></meta>
        <meta property="og:image:width" content="1200"></meta>
        <meta property="og:image:height" content="630"></meta>

        {/* Add more meta tags as needed */}
      </Helmet>
      <span className="bg-gradient rounded-md px-2 py-2 text-sm text-white">
        Technology
      </span>
      <h1 className="text-4xl font-semibold text-rebin-darkblue">
        Fill level data helps Moorslede stay compliant with waste management legislation
      </h1>
      <div className="flex gap-x-6">
        <span className="text-sm">2 min</span>
        <span className="text-sm">Jul 29, 2024</span>
      </div>
      <div className="my-10 h-80 w-full rounded-xl">
        <img
          src={MoorsledeImg}
          alt="A newly installed bin using the Rebin Smart Waste Sensor in Maarkedal"
          className="h-80 w-full rounded-xl object-cover"
        />
      </div>
      <div className="text-justify text-lg font-light">
        Managing public waste is a significant challenge for nearly every local government, and Moorslede in Belgium, is no exception. Ensuring the availability of sufficient bins and timely emptying or replacement is a complex task, further complicated by regional regulations. In Flanders, every local government must develop a comprehensive waste bin management plan by 2030. This plan requires creating an inventory of waste bins, monitoring fill levels, and implementing necessary reporting, analysis, and adjustments.
      </div>
      <div className="text-justify text-lg font-light">
        To address these challenges, Moorslede has embraced an innovative solution by partnering with Rebin. Rebin has installed 58 sensors across the municipality to enhance waste management efficiency. These sensors monitor the fill levels and usage of waste bins, providing real-time data that allows for timely and precise interventions.
      </div>
      <div className="flex justify-center">
        <q className="text-rebin-darkblue block text-xl font-semibold">
          Rebin's solution saves us valuable time, automates collection, and ensures we remain compliant with regulations.
        </q>
      </div>
      <div className="text-justify text-lg font-light">
        With real-time monitoring of public bins, waste ollections become more efficient, and local governments can precisely identify areas where bins are needed or where there are excess. This ensures that waste management plans are always up-to-date, compliance with legislation is maintained, and attention can be focused on addressing problem areas.
      </div>
      <section className="flex flex-col gap-y-2">
        <span className="text-rebin-darkblue mb-5 font-bold">Summary:</span>
        <div className="flex items-center gap-x-5">
          <span className="text-gradient font-bold">1.</span>
          <span className="text-justify text-lg font-light">
            Moorslede struggles with managing waste bins and meeting Flanders' 2030 regulations.
          </span>
        </div>
        <div className="flex items-center gap-x-5">
          <span className="text-gradient font-bold">2.</span>
          <span className="text-justify text-lg font-light">
            Rebin provided an up-to-date bin inventory and installed fill level sensors in every bin.
          </span>
        </div>
        <div className="flex items-center gap-x-5">
          <span className="text-gradient font-bold">3.</span>
          <span className="text-justify text-lg font-light">
            Result: Optimized collections, accurate bin management, and compliance with regulations
          </span>
        </div>
      </section>
      <section className="border-y-2 border-slate-100 p-10">
        <div className="mx-auto flex max-w-sm flex-col justify-center gap-y-5 sm:flex-row sm:gap-x-5">
          <span className="bg-gradient rounded-md px-2 py-2 text-center text-sm text-white">
            Smart city
          </span>
          <span className="bg-gradient rounded-md px-2 py-2 text-center text-sm text-white">
            Waste management
          </span>
          <span className="bg-gradient rounded-md px-2 py-2 text-center text-sm text-white">
            Sensors
          </span>
        </div>
      </section>
    </main>
  );
};

export default MoorsledeBlog;
